import { useState, useEffect } from 'react';
import { RewardBenefitByTier } from './rewardTypes';
import api from './rewardApi';

const useGetRewardFaq = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const fetchFaq = async () => {
      const response = await api.getRewardFaq();
      if (response.ok) {
        setFaqs(response.data);
      }
    };
    fetchFaq();
  }, []);

  return faqs;
};

const useGetRewardBenefits = () => {
  const [benefitList, setBenefitList] = useState<RewardBenefitByTier>({
    millionaire: [],
    billionaire: [],
    trillionaire: [],
  });

  useEffect(() => {
    const fetchBenefits = async () => {
      const response = await api.getTierBenefitList();
      if (response.ok) {
        setBenefitList(response.data.data);
      }
    };
    fetchBenefits();
  }, []);

  return benefitList;
};

export { useGetRewardFaq, useGetRewardBenefits };
