import React from 'react';
import Image from 'next/image';
import { RewardBenefit } from 'entity/reward/rewardTypes';
import { rewardOnboarding } from 'theme';

const BenefitItem = (props: {
  benefit: RewardBenefit;
  showDivider: boolean;
}) => {
  const { benefit, showDivider } = props;
  const { title, description, image } = benefit;

  return (
    <div className="flex flex-col gap-4 w-full items-center">
      <div className="flex items-center w-full gap-2">
        <div className="min-h-[64px] min-w-[64px]">
          <Image
            src={image || rewardOnboarding.billionaire}
            height={64}
            width={64}
          />
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-bold">{title}</p>
          <p className="text-neutral-500">{description}</p>
        </div>
      </div>
      {showDivider && <div className="horizontal-divider" />}
    </div>
  );
};

const BenefitList = (props: { benefits: RewardBenefit[] }) => {
  const { benefits } = props;

  const dataSource = benefits.map((benefit, index) => {
    const showDivider = index < benefits.length - 1;
    return (
      <BenefitItem
        showDivider={showDivider}
        key={benefit.id}
        benefit={benefit}
      />
    );
  });

  return <div className="list gap-4">{dataSource}</div>;
};

export default BenefitList;
