import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'seedly-component-library/button-new';
import { TierMap } from 'entity/reward/rewardTypes';
import { rewardOnboarding } from 'theme';
import Image from 'next/image';
import BenefitList from 'pageContents/RewardAboutContent/BenefitList';
import { useRouter } from 'next/router';
import ModalActions from 'reducer/modalRedux';
import { RootState } from 'reducer';
import { useGetRewardBenefits } from 'entity/reward/rewardHooks';
import Dialog from 'seedly-component-library/dialog';
import TierColorContainer from '../TierColorContainer';

const TierUpModal = () => {
  const benefitList = useGetRewardBenefits();

  const profile = useSelector((state: RootState) => state.profile.user);
  const isOpen = useSelector(
    (state: RootState) => state.modal.isTierUpModalOpen,
  );
  const dispatch = useDispatch();
  const levelUpTier = profile?.tier || 1;
  const tierTitle = TierMap[levelUpTier];

  const router = useRouter();

  return (
    <Dialog
      isOpen={isOpen}
      widthSize="sm"
      onCloseClick={() => dispatch(ModalActions.setTierUpModal(false))}
      contentClassName="p-0"
    >
      <TierColorContainer
        className="flex p-4 pt-8 rounded-t-2xl"
        tier={levelUpTier}
      >
        <div className="flex flex-col sapce-y-4 items-center w-full">
          <div className="flex flex-col w-full items-center">
            <p className="text-base font-bold text-white">
              HOORAY! YOU’RE NOW A {tierTitle.toUpperCase()}!
            </p>
            <p className="text-white">
              You can now enjoy these additional benefits!
            </p>
          </div>
          <Image src={rewardOnboarding.billionaire} height={200} width={200} />
        </div>
      </TierColorContainer>
      <div className="p-4">
        <BenefitList benefits={benefitList[tierTitle.toLowerCase()]} />
      </div>
      <div className="flex items-center gap-4 p-4 pt-0 w-full">
        <Button
          onClick={() => {
            router.push('/rewards/about');
            dispatch(ModalActions.setTierUpModal(false));
          }}
          isBlock
          size="lg"
          variant="outline"
        >
          More about tiers
        </Button>
        <Button
          onClick={() => {
            router.push('/rewards');
            dispatch(ModalActions.setTierUpModal(false));
          }}
          isBlock
          size="lg"
          variant="primary"
        >
          Discover Rewards
        </Button>
      </div>
    </Dialog>
  );
};

export default TierUpModal;
